
import Vue from "vue";
import { InformationData, Session } from "@/types/interfaces";

export default Vue.extend({
  name: "SessionCancellation",
  props: {
    session: {
      type: Object as () => Session
    },
    isCancelled: {
      type: Boolean
    }
  },
  data() {
    return {
      // Table data
      infoData: [
        {
          name: "price",
          label: (this as any).$t("sessions.infoDataYouHavePaid"),
          icon: "profile",
          value: this.session.price
        },
        {
          name: "cancellationFee",
          label: (this as any).$t("sessions.infoDataCancellationFee"),
          icon: "payment",
          value: this.session.cancellationFee
        },
        {
          name: "returned",
          label: (this as any).$t("sessions.infoDataReturnedToYou"),
          icon: "platform",
          value: "$ 45"
        }
      ] as InformationData[]
    };
  }
});
